import React, { useState } from 'react'
import {Helmet} from "react-helmet";

import HubspotForm from 'react-hubspot-form'

import * as styles from './styles.module.css'

export const MeetingContactForm = ({ pageData }) => {
    const [isScheduling, setIsScheduling] = useState(false);

    return (
        <div>
            <div className={styles.description}>
                {pageData.subTitle}
            </div>
            <HubspotForm
                portalId={pageData.hubspotPortalId}
                formId={pageData.hubspotFormId}
                onSubmit={(formData) => {
                    // formData.push({name: 'reason', value: 'web-site-test'})
                    // console.log("[onFormSubmit] ", formData);
                    setIsScheduling(true);
                }}
                onReady={() => {
                    // HACK: This script is a hack to make onSubmit callback work.
                    const script = document.createElement('script');
                    script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
                    let jqueryScript = document.getElementsByTagName('script');
                    let src = Array.from(jqueryScript).filter(item => item.src === script.src)
                    if(src.length === 0) {
                        document.body.appendChild(script)
                    }
                }}
                // loading={<div>Loading...</div>}
            />

            {isScheduling && <div>
                <div className="meetings-iframe-container"
                        data-src={pageData.hubspotMeetingUrl}>
                </div>

                <Helmet>
                    <script type="text/javascript"
                            src="https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js">
                    </script>
                </Helmet>
            </div>}

        </div>
    )
}
