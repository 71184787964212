export const DEMO = {
    mainTitle: 'Demo',
    otherDemoOptionsText: 'You can also explore on your own using:',
    blocks: [
        {
            title: 'Video Walthroughs',
            content:
                'Check out product demos on Youtube. Video library covers different cases and capabilities.',
            icon: '/img/contactPages/video-demo.svg',
            link: '/youtube',
            linkTarget: "_blank",
            btnText: 'Watch',
        },
        {
            title: 'Open-Source Demo',
            content:
                'You can also try Kubevious open-source on your own. No registration required.',
            icon: '/img/contactPages/self-service.svg',
            link: 'https://demo.kubevious.io',
            linkTarget: "_blank",
            btnText: 'Interact',
        },
    ],
}
