import React from 'react'
import { DEMO } from '../../../content/data/demoPage'
import Layout from '../../components/Layout'
import InnerLayout from '../../components/InnerLayout'

import { MeetingContactForm } from '../../components/MeetingContactForm'

import { DEMO_REQUEST } from '../../../content/data/contactPages'

import '../../styles/inner-pages.css'

import * as styles from './styles.module.css'
import * as commonStyles from '../../styles/common-components.module.css'

const Demo = () => {
    const pageData = DEMO

    return (
        <Layout
            title={pageData.mainTitle}
            forceFullLogo
            seoTitle="Kubevious :: Demo"
            seoDescription="Check out the Kubevious demo"
        >
            <InnerLayout>

                <MeetingContactForm pageData={DEMO_REQUEST} />

                <div className={styles.otherOptionsText}>
                    {pageData.otherDemoOptionsText}
                </div>

                <div className={styles.blocks}>
                    {pageData.blocks.map((block, index) => (
                        <div className={styles.block} key={index}>
                            <div className={styles.blockWrapper}>
                                <img
                                    src={block.icon}
                                    className={styles.icon}
                                    alt="icon"
                                />

                                <div className={styles.blockTitle}>
                                    {block.title}
                                </div>
                                <div className={styles.blockContent}>
                                    {block.content}
                                </div>
                            </div>

                            <a
                                className={`${commonStyles.linkButton} ${styles.link}`}
                                href={block.link}
                                target={block.linkTarget}
                            >
                                {block.btnText}
                            </a>
                        </div>
                    ))}
                </div>
            </InnerLayout>
        </Layout>
    )
}

export default Demo
